/* #wsp{
    display: initial;
} */

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: -2px !important;
    bottom: 20px !important;

  }
  .grecaptcha-badge:hover {
    width: 256px !important;
  } 

