.page-section {
  padding: 6rem 0;
}
.page-section .page-section-heading {
  font-size: 2.25rem;
  line-height: 2rem;
}
@media (min-width: 992px) {
  .page-section .page-section-heading {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}

.portfolio .portfolio-item {
  cursor: pointer;
  position: relative;
  display: block;
  max-width: 25rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.portfolio .portfolio-item .portfolio-item-caption {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: #19b9e7;
}
.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 0.8;
}
.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
  font-size: 1.5rem;
}

.portfolio-modal .btn-close {
  color: #1abc9c;
  font-size: 2rem;
  padding: 1rem;
}
.portfolio-modal .portfolio-modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
}
@media (min-width: 992px) {
  .portfolio-modal .portfolio-modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}

.divider-custom {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider-custom .divider-custom-line {
  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: white;
  border-radius: 1rem;
  border-color: #2c3e50;
}
.divider-custom .divider-custom-line:first-child {
  margin-right: 1rem;
}
.divider-custom .divider-custom-line:last-child {
  margin-left: 1rem;
}
.divider-custom .divider-custom-icon {
  color: #2c3e50;
  font-size: 2rem;
}
.divider-custom.divider-light .divider-custom-line {
  background-color: #fff;
}