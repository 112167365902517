.form-box {
  padding-bottom: '400px';
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-top {
  overflow: hidden;
  padding: 0 25px 15px 25px;
  /*   background: #000000; */
  background: rgb(255, 255, 255);
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  text-align: left;
  background: #19b9e7;
}

button.btn {
  height: 50px;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;
  background: #19b9e7;
  border: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s;
}


.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  /*   -webkit-appearance: button; */
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  line-height: 30px;
  text-align: center;
}


::selection {
  background: #19b9e7;
  color: #fff;
  text-shadow: none;
}

.form-top-right {
  float: left;
  width: 25%;
  padding-top: 5px;
  font-size: 66px;
  /*   color: #fff; */
  line-height: 100px;
  text-align: right;
  opacity: 0.3;
}

.form-text {
  padding: 25px 25px 30px 25px;
  background: #444;
  background: rgba(0, 0, 0, 0.3);
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  text-align: left;
}

.form-bottom {
  padding: 25px 25px 30px 25px;
  background: #444;
  background: rgba(0, 0, 0, 0.3);
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  text-align: right;
}

.form-group {
  margin-bottom: 15px;
}

.form-top-left {
  float: left;
  width: 75%;
  padding-top: 25px;
  background: #19b9e7;
  color: white
}


.content-form {
  top: 100px;
  width: 100%;

  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}