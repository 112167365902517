.navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon-bg) !important;

  }
  .navbar {
  --bs-navbar-toggler-icon-bg:  url('../assets/menu/btn.svg') !important;
  /* padding:  0;
  margin: 0; */
    }
  
  .offcanvas {
    --bs-offcanvas-bg: '#ccc' !important;
    --bs-offcanvas-border-color: var(--bs-border-color-translucent) !important;
    --bs-offcanvas-border-width: var(--bs-border-width) !important;
    --bs-border-width: 4px;
    --bs-border-color-translucent: darkgrey;
    opacity: 0.9 !important;
    --bs-offcanvas-width: 300px !important;
    }
  
    .btn-close {
      --bs-btn-close-color: #fff !important;
      --bs-btn-close-bg: url('../assets/menu/close-button.svg') !important;
      /* --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e); */
      --bs-btn-close-opacity: 0.5;
      --bs-btn-close-hover-opacity: 0.75;
      --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      --bs-btn-close-focus-opacity: 1;
      --bs-btn-close-disabled-opacity: 0.25;
      --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
      box-sizing: content-box;
      width: 1em;
      height: 1em;
      padding: .25em .25em;
      color: var(--bs-btn-close-color);
      background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
      border: 0;
      border-radius: .375rem;
      opacity: var(--bs-btn-close-opacity);
  }





  .offcanvas.show:not(.hiding), .offcanvas.showing {
    transform: none;
}
.offcanvas.hiding, .offcanvas.show, .offcanvas.showing {
    visibility: visible;
    background-color: darkgrey;
    
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
    background-color: darkgrey;
}
.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
/*     color: var(--bs-offcanvas-color); */
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
}