 .content-video {
   position: fixed;
   width: 100%;
   z-index: 3;  
   display: flex;
   align-items: center;
   color: rgb(7, 0, 0);
   object-fit: cover;
 }

 #video2 {
   position: relative;
   max-width: 100%;
   z-index:1;   
   flex-direction: column;
   object-fit: contain;
   top: 0;
   left: 0;
 }


 #video3 {
  position: absolute;
  display: flex;
  max-width: 100%;
  z-index:1;   
  flex-direction: column;
  object-fit: contain;
  padding-top: 0px;
  left: 0;}